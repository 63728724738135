<template>
  <div class="cr-split-wrapper">
    <GroupData v-if="!isTrash" />
    <Contacts v-if="toggleType === 'LIST' || isTrash" />
    <History v-else />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import GroupData from "./group";
import Contacts from "./contacts";
import History from "./history";
export default {
  props: {
    toggleType: {
      type: String,
      default: "LIST",
      required: true
    }
  },
  components: { GroupData, Contacts, History },
  computed: {
    ...mapGetters("customerRoute", ["getRouteInfo"]),
    isTrash() {
      const { type } = this.getRouteInfo;
      return type === "TRASH";
    }
  },
  watch: {
    getRouteInfo(n, p) {
      const { groupId, page } = n;
      const { groupId: p_groupId, page: p_page } = p;

      if (groupId !== p_groupId || page !== p_page) {
        this.SET_CARD_DETAIL({});
      }
    }
  },
  methods: {
    ...mapMutations("cardDetail", ["SET_CARD_DETAIL"])
  }
};
</script>

<style lang="scss" scoped>
.cr-split-wrapper {
  display: flex;
  position: relative;
  height: 100%;
}

/* delete */
.cr-customer-wrapper {
  display: flex;
  position: relative;
  height: 100%;
  background-color: #f9f9f9;
  &.show-select-box {
    height: calc(100% - 129px);
  }
}
</style>
