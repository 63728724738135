<template>
  <div>
    <!-- 보기모드 -->
    <MemberView v-if="!isEdit" />
    <MemberEdit ref="memberEdit" v-else />
  </div>
</template>

<script>
import MemberView from "./MemberView";
import MemberEdit from "./MemberEdit";

export default {
  props: {
    isEdit: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  components: { MemberView, MemberEdit }
};
</script>

<style lang="scss" scoped></style>
