<template>
  <v-card-text class="pb-0">
    <CustomerGroupDialog
      v-if="dialogType === 'customerGroup'"
      v-bind="$props"
      v-on="$listeners"
    />
    <CustomerCardDialog
      v-if="dialogType === 'customerCard'"
      v-bind="$props"
      v-on="$listeners"
    />
    <CustomerCardRestoreDialog
      v-if="dialogType === 'customerCardRestore'"
      v-bind="$props"
      v-on="$listeners"
    />
    <CustomerAdminDialog
      v-if="dialogType === 'customerAdmin'"
      v-bind="$props"
      v-on="$listeners"
    />
    <CustomerSearchListDialog
      v-if="dialogType === 'customerSearch'"
      v-bind="$props"
      v-on="$listeners"
    />
    <CustomerMemoDialog
      v-if="dialogType === 'customerMemo'"
      v-bind="$props"
      v-on="$listeners"
    />
  </v-card-text>
</template>

<script>
import { mapGetters } from "vuex";
import CustomerCardDialog from "./CustomerCardDialog";
import CustomerCardRestoreDialog from "./CustomerCardRestoreDialog";
import CustomerAdminDialog from "./CustomerAdminDialog";
import CustomerSearchListDialog from "./CustomerSearchListDialog";
import CustomerMemoDialog from "./CustomerMemoDialog.vue";
import CustomerGroupDialog from "./CustomerGroupDialog.vue";

export default {
  components: {
    CustomerCardDialog,
    CustomerCardRestoreDialog,
    CustomerAdminDialog,
    CustomerSearchListDialog,
    CustomerMemoDialog,
    CustomerGroupDialog
  },
  props: {
    confirm: {
      default: false,
      type: Boolean,
      description: "확인버튼"
    },
    confirmDisabled: {
      default: false,
      type: Boolean,
      description: "확인 버튼 Disabled 여부"
    },
    expand: {
      default: false,
      type: Boolean,
      description: "더보기"
    }
  },
  computed: {
    ...mapGetters("customerDialog", ["dialogType"])
  }
};
</script>
