<template>
  <v-container class="pa-0 ma-0">
    <MemoView ref="view" v-if="dialogMode === MEMO_DIALOG_TYPES.VIEW" />
    <MemoUpdate ref="update" key="memoDialog" v-else />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import MemoUpdate from "@/customer/views/components/common/MemoUpdate";
import MemoView from "../common/MemoView";
import { MEMO_DIALOG_TYPES } from "@/customer/constant/constants";

export default {
  components: { MemoView, MemoUpdate },
  props: {
    confirm: {
      default: false,
      type: Boolean,
      description: "확인버튼"
    },
    expand: {
      default: false,
      type: Boolean,
      description: "더보기"
    }
  },
  watch: {
    confirm: function(next) {
      if (next) {
        this.$refs.update.updateMemoFunc();
      }
    }
  },
  async mounted() {
    const { mode } = this.getParams;
    this.dialogMode = mode;
  },
  data: () => ({
    dialogMode: MEMO_DIALOG_TYPES.VIEW
  }),
  computed: {
    MEMO_DIALOG_TYPES() {
      return MEMO_DIALOG_TYPES;
    },
    ...mapGetters("customerDialog", ["getParams"])
  },
  methods: {}
};
</script>
