<template>
  <ul class="mt-3">
    <li
      class="black--text align-left"
      v-for="item in getCustomerAdminList"
      :key="item.id"
    >
      {{ username(item.userDetail) }}
    </li>
  </ul>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  props: {
    confirm: {
      default: false,
      type: Boolean,
      description: "확인버튼"
    },
    expand: {
      default: false,
      type: Boolean,
      description: "더보기"
    }
  },
  watch: {},
  async mounted() {},
  data: () => ({
    id: 0
  }),
  computed: {
    ...mapGetters("customerDialog", ["getParams"]),
    ...mapGetters("customerGroup", ["getCustomerAdminList"])
  },
  methods: {
    ...mapMutations("customerDialog", ["CLOSE_DIALOG"]),
    ...mapActions("snackbar", ["openSnackbar"]),
    ...mapActions("confirm", { confirmDialog: "confirm" }),
    username(user) {
      let username = user.name;
      if (user.organization) {
        username += ` / ${user.organization}`;
      }
      if (user.jobTitle) {
        username += ` / ${user.jobTitle}`;
      }
      if (user.jobGrade) {
        username += ` / ${user.jobGrade}`;
      }
      return username;
    }
  }
};
</script>
