<template>
  <div class="contacts-wrap d-flex flex-grow-1">
    <UnifiedView v-if="!loading && !isEmpty" />
    <EmptyMessage v-if="isEmpty" :text="$t('customer.message.noContact')" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UnifiedView from "./unified";
import EmptyMessage from "@/customer/views/components/list/common/EmptyMessage.vue";

export default {
  components: { UnifiedView, EmptyMessage },
  computed: {
    ...mapGetters("customerCardDetail", ["isEmpty", "loading"])
  }
};
</script>

<style lang="scss" scoped>
.contacts-wrap {
  min-width: 400px;
  overflow-y: auto;
  background-color: #f9f9f9;
}
</style>
