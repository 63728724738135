<template>
  <!-- 주소록 리스트 -->
  <div class="d-flex fill-height">
    <v-data-table
      show-select
      fixed-header
      hide-default-header
      hide-default-footer
      item-key="id"
      :item-class="itemClass"
      :loading="isLoading"
      :headers="headers"
      :items="getCardList"
      :items-per-page="getPageSize"
      :server-items-length="getTotalCount"
      v-model="_selectedItems"
      @page-count="pageCount = $event"
      @item-selected="itemSelected"
      @click:row="r => getContactDetail(r.id)"
    >
      <template v-slot:[`item.userName`]="{ item }">
        <ListItem :item="item" v-bind="$props" />
      </template>
      <template v-slot:footer>
        <Pagination
          v-if="pageCount > 0"
          type="list"
          :page.sync="page"
          :pageSize="getPageSize"
          :blockSize="3"
          :totalElements="getTotalCount"
          @pageClick="pageClick"
        />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import ListItem from "./item";
import Pagination from "@/commons/views/Pagination";

export default {
  components: { ListItem, Pagination },
  props: {
    selectedItems: {
      type: Array,
      default: () => []
    }
  },
  created() {
    // url로 들어왔을때 page값 설정
    this.page = this.getPage;
  },
  data() {
    return {
      page: 1,
      pageCount: 0,
      headers: [{ value: "userName" }]
    };
  },
  computed: {
    ...mapGetters("customerCardDetail", ["detail"]),
    ...mapGetters("customerRoute", ["getPage", "getPageSize", "getRouteInfo"]),
    ...mapGetters("customerCard", [
      "getCardList",
      "getTotalCount",
      "isLoading"
    ]),
    _selectedItems: {
      get() {
        return this.selectedItems;
      },
      set(selectedItems) {
        this.$emit("update:selectedItems", selectedItems);
      }
    }
  },
  methods: {
    ...mapMutations("selectBox", ["SET_SELECT_ITEM"]),
    ...mapActions("customerCardDetail", ["getContactDetail"]),
    itemClass(item) {
      let cls = "item-row";
      if (this.detail?.id === item.id) {
        cls += " cr-selected";
      }

      return cls;
    },
    pageClick(page) {
      // routing시 전달되는 parameter가 있는 지 체크
      const { actionObj } = this.$route.params;
      const name = actionObj ? "customer_list_action" : "customer_list";
      let { type, groupId } = this.getRouteInfo;
      //groupId = virtualContactGroupConverter(groupId);
      this.page = page;
      // 페이지 정보 를 가지고 라우팅 진행
      this.$router.push({ name, params: { type, groupId, page, actionObj } });
    },

    itemSelected(selected) {
      // selectBox사용 안할 시 return
      //if (!this.isUseSelectBox) return;
      this.SET_SELECT_ITEM(selected.item);
    }
  }
};
</script>

<style lang="scss" scoped>
.v-data-table::v-deep {
  min-width: 400px;
  border-radius: 0px;
  border-right: thin solid rgba(0, 0, 0, 0.08);
  .v-data-table__wrapper {
    height: calc(100% - 44px);
    table tbody tr {
      td {
        padding: 0 14px !important;
      }

      > td:first-child {
        width: 44px;
        min-width: 44px;
        max-width: 44px;
        padding-left: 16px !important;
      }

      td.importantStatus {
        width: 44px;
        min-width: 44px;
        max-width: 44px;
        padding: 0 4px !important;
      }

      &.cr-selected td {
        background-color: #efefef !important;
      }
      &.v-data-table__selected {
        background: transparent;
      }
    }
  }
}
</style>
