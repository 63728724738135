<template>
  <div class="cr-customer-main cr-wrapper">
    <div
      v-if="getCurrentGroup"
      class="cr-container ch-2 d-flex flex-column"
      :class="`cr-container-${this.$vuetify.breakpoint.name}`"
    >
      <DeletedBanner />
      <ToggleView :viewToggle.sync="toggleType" v-if="isCustomer" />
      <v-divider v-if="!isRoot" />

      <div class="cr-customer-detail-area" v-if="!isRoot" :style="calcHeight">
        <OuterSplit :toggleType="toggleType" />
      </div>
      <div class="cr-customer-home-area" v-else>
        <Home />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ToggleView from "./ToggleView.vue";
import OuterSplit from "./split";
import Home from "./home";
import DeletedBanner from "./DeletedBanner.vue";

export default {
  components: { DeletedBanner, Home, OuterSplit, ToggleView },
  computed: {
    ...mapGetters("customerGroup", ["getCurrentGroup"]),
    ...mapGetters("customerRoute", ["getRouteInfo"]),
    isRoot() {
      const { type } = this.getRouteInfo;
      return !type || type === "ROOT";
    },
    isCustomer() {
      const { type } = this.getRouteInfo;
      return !!(type && type === "CUSTOMER");
    },
    calcHeight() {
      const { type } = this.getRouteInfo;
      let toMinus = type === "TRASH" ? 0 : 45;
      return `height:calc(100% - ${toMinus}px`;
    }
  },
  methods: {
    ...mapActions("confirm", ["confirm", "disagree"])
  },
  data() {
    return {
      toggleType: "LIST",
      isCard: true,
      toggleCheck: false,
      loading: true,
      options: {
        mustSort: true,
        sortBy: ["userName"],
        sortDesc: [false]
      },
      consonantSelected: undefined,
      consonantList: [
        { label: "전체" },
        { label: "가", value: "ㄱ" },
        { label: "나", value: "ㄴ" },
        { label: "다", value: "ㄷ" },
        { label: "라", value: "ㄹ" },
        { label: "마", value: "ㅁ" },
        { label: "바", value: "ㅂ" },
        { label: "사", value: "ㅅ" },
        { label: "아", value: "ㅇ" },
        { label: "자", value: "ㅈ" },
        { label: "차", value: "ㅊ" },
        { label: "카", value: "ㅋ" },
        { label: "타", value: "ㅌ" },
        { label: "파", value: "ㅍ" },
        { label: "하", value: "ㅎ" },
        { label: "A-Z", value: "A" },
        { label: "0-9", value: "0" },
        { label: "중요", value: "I" }
      ],
      selectedCount: 0,
      sortBy: "userName",
      sortDesc: false,
      dialog: {
        card: {},
        showDialog: false,
        parentFolder: null,
        folder: null
      },
      isNew: false,
      showConsonant: false,
      helperLabel: "그룹에 연락처 $n개 이동"
    };
  }
};
</script>

<style lang="scss" scoped>
.cr-wrapper {
  position: relative;
  height: 100%;
  .cr-container {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    overflow: hidden;
    //.cr-list-toggle-header {
    //  height: 45px;
    //}
    .cr-customer-detail-area {
      overflow-y: hidden;
      display: flex;
      flex-direction: column;
    }
    .v-data-table ::v-deep {
      position: absolute;
      width: 100%;
      top: 0px;
      bottom: 0px;
      /* content body */
      .v-data-table__wrapper {
        //height: calc(100% - 54px);
        overflow-y: auto;
        overflow-x: hidden;
      }
      .cr-use-selectbox {
        height: calc(100% - 144px);
      }
      /* row style */
      .v-input--selection-controls__input {
        margin: 0px;
      }
      .v-data-table-header {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        > tr > th > .helper {
          font-size: 18px;
        }
      }

      .item-row {
        > .text-start {
          max-width: 1px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        > .text-start.importantStatus {
          overflow: inherit;
        }
      }
      .min-width {
        min-width: 170px;
      }
    }
  }
  /* 초성 검색 */
  .cr-consonant ::v-deep {
    z-index: 2;
    position: absolute;
    right: 112px;
    top: 36px;
    .v-item-group {
      z-index: 2;
      padding: 0px;
      background-color: #fff;
    }
    .v-item-group > .cr-consonant-btn {
      min-width: 0px;
      padding: 5px;
      margin: 0px;
      height: inherit;
    }
  }
  .cr-customer-home-area {
    height: 100%;
  }
}
</style>
