<template>
  <div style="display: contents;">
    <div class="cr-split-wrapper" :class="{ 'show-select-box': showSelectBox }">
      <!-- 목록 -->
      <CardList
        :selectedItems="selectedItems"
        @update:selectedItems="items => $emit('update:selectedItems', items)"
      />
      <!-- 미리보기 -->
      <CardDetail />
    </div>

    <SelectBox
      v-if="showSelectBox"
      moduleType="contact"
      @resetSelectedItems="$emit('clearSelectedItems')"
      @selectedItemCancel="i => $emit('selectedItemCancel', i)"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import CardList from "./cardList";
import CardDetail from "./cardDetail";
import SelectBox from "@/commons/views/SelectBox";

export default {
  props: {
    selectedItems: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isUseSelectBox: true
    };
  },
  components: { CardList, CardDetail, SelectBox },
  computed: {
    ...mapGetters("customerRoute", ["getRouteInfo"]),
    ...mapGetters("selectBox", ["isShowBox", "getSelectBoxItems"]),
    showSelectBox() {
      return this.isUseSelectBox && this.isShowBox;
    }
  },
  watch: {
    getRouteInfo(n, p) {
      const { groupId, page } = n;
      const { groupId: p_groupId, page: p_page } = p;

      if (groupId !== p_groupId || page !== p_page) {
        this.SET_CARD_DETAIL({});
      }
    }
  },
  methods: {
    ...mapMutations("customerCardDetail", ["SET_CARD_DETAIL"])
  }
};
</script>

<style lang="scss" scoped>
.cr-split-wrapper {
  display: flex;
  position: relative;
  height: calc(100% - 40px);
  &.show-select-box {
    height: calc(100% - 129px);
  }
}
</style>
