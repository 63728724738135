<template>
  <v-container>
    <Detail />
  </v-container>
</template>

<style lang="scss" scoped>
.cr-contact-unified {
  position: absolute;
  top: 0;
  left: 400px;
  right: 0;
  bottom: 0;
  display: flex;
  min-width: 800px;
  overflow-x: auto;
}
</style>

<script>
import Detail from "./detail";

export default {
  components: { Detail },
  computed: {}
};
</script>
