<template>
  <div class="fill-height">
    <CustomerConfig v-if="$route.name.split('_')[1] === 'config'" />
    <CardMain v-else />
    <Dialog />
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import CardMain from "@/customer/views/components/list/CardMain.vue";
import Dialog from "@/customer/views/components/Dialog";
import CustomerConfig from "@/customer/views/components/CustomerConfig.vue";

export default {
  components: {
    CustomerConfig,
    Dialog,
    CardMain
  },
  async created() {
    await this.SET_SHOW_SPLASH(false);
  },
  destroyed() {
    this.SET_ACTIVE_ITEMS([]);
    this.SET_OPEN_ITEMS([]);
  },
  methods: {
    ...mapMutations("splash", ["SET_SHOW_SPLASH"]),
    ...mapMutations("customerGroup", ["SET_ACTIVE_ITEMS", "SET_OPEN_ITEMS"])
  }
};
</script>
