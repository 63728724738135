<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-text-field
          required
          autofocus
          autocomplete="off"
          ref="groupName"
          :label="$t('customer.name')"
          class="cr-group-name-field"
          v-model="groupName"
          :error-messages="groupNameError"
          @keydown.enter="addCustomerGroupFunc()"
        />
      </v-col>
      <!--
        <v-col cols="12">
          <div>
            <v-text-field
              dense
              outlined
              height="50"
              hide-details
              autocomplete="off"
              tabindex="1"
              ref="domain"
              :placeholder="$t('board.도메인')"
              v-model="domain"
            ></v-text-field>
          </div>
        </v-col>
        -->
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import i18n from "@/_locales";
import { fetchDuplicationCheck } from "@/customer/api/group.api";
import { GROUP_TYPES } from "@/customer/constant/constants";

export default {
  props: {
    confirm: {
      default: false,
      type: Boolean,
      description: "확인버튼"
    }
  },
  async mounted() {
    if (this.getParams.parentCustomerGroup) {
      this.parentId = this.getParams.parentCustomerGroup.id;
    }
  },
  watch: {
    confirm: function(next) {
      if (next) {
        this.addCustomerGroupFunc();
      }
    },
    contactGroupType: function() {
      return this.$refs.groupName.focus();
    },
    async groupName(val) {
      await this.checkGroupNameExists(val);
    }
  },
  data() {
    return {
      id: 0,
      parentId: 0,
      groupName: "",
      domain: "",
      groupNameError: "",
      isPublic: true
    };
  },
  computed: {
    ...mapGetters("customerDialog", ["getParams"]),
    ...mapGetters("auth", ["getUserInfo"]),
    ...mapGetters("customerRoute", ["getRouteInfo"])
  },
  methods: {
    ...mapMutations("customerDialog", ["CLOSE_DIALOG"]),
    ...mapActions("customerGroup", ["addCustomerGroup", "updateCustomerGroup"]),
    ...mapActions("customerDialog", ["confirmCallback"]),
    ...mapActions("snackbar", ["openSnackbar"]),
    async addCustomerGroupFunc() {
      const { groupName } = this;

      const { parentCustomerGroup } = this.getParams;
      const parentId = parentCustomerGroup ? parentCustomerGroup.id : 0;
      this.groupName = groupName.trim();

      if (!this.validation()) {
        return this.$emit("update:confirm", false);
      }

      const { result, id } = await this.addCustomerGroup({
        groupName: this.groupName,
        domain: this.domain,
        parentId: parentId > 0 ? parentId : 0,
        isPublic: this.isPublic
      });
      if (result) {
        await this.confirmCallback();
        this.CLOSE_DIALOG();
        await this.$router.push({
          name: "customer_list",
          params: { type: GROUP_TYPES.CUSTOMER, groupId: id, page: 1 }
        });
      }
    },
    remove(item) {
      this.products.splice(this.products.indexOf(item), 1);
    },
    validation() {
      let isValidated = true;
      let message = "";
      // 그룹네임 체크
      if (!this.groupName) {
        this.$refs.groupName.focus();
        isValidated = false;
        message = i18n.t("customer.validate.groupName");
      }
      if (!this.isPublic && this.members.length < 1) {
        isValidated = false;
        message = i18n.t("customer.validate.member");
      }
      if (this.groupNameError) {
        isValidated = false;
        message = i18n.t("customer.validate.duplicated");
      }
      if (!isValidated) {
        this.openSnackbar({ message: message, type: "ERROR" });
      }
      return isValidated;
    },
    async checkGroupNameExists(name) {
      const { data } = await fetchDuplicationCheck({
        groupName: name,
        domain: ""
      });
      if (data.isNameExists) {
        this.groupNameError = i18n.t("customer.validate.duplicated");
      } else {
        this.groupNameError = "";
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.customer-dialog-wrapper ::v-deep {
  max-width: 400px;
  .cr-group-name-field ::v-deep {
    .v-text-field__details {
      margin-bottom: 0;
    }
  }
}
</style>
