<template>
  <div class="cr-card-detail-options">
    <div class="cr-card-detail-option">
      <v-icon>
        mdi-domain
      </v-icon>
      <span class="text-truncate">
        {{ getCurrentGroup.groupName }}
      </span>
    </div>
    <div class="cr-card-detail-option">
      <v-icon>
        mdi-web
      </v-icon>
      <span class="text-truncate">
        {{ getCurrentGroup.domain }}
      </span>
    </div>
    <div class="cr-card-detail-option">
      <v-icon>
        mdi-link
      </v-icon>
      <span class="text-truncate">
        {{ getCurrentGroup.url }}
      </span>
    </div>
    <div class="cr-card-detail-option">
      <v-icon>
        mdi-map-marker-outline
      </v-icon>
      <div class="d-flex align-center">
        {{ getCurrentGroup.post }}
        {{ getCurrentGroup.locality }}
      </div>
      <div>
        {{ getCurrentGroup.streetAddress }}
      </div>
    </div>
    <div class="cr-card-detail-option">
      <v-icon>
        mdi-phone
      </v-icon>
      <span class="text-truncate">
        {{ getCurrentGroup.tel }}
      </span>
    </div>
    <div class="cr-card-detail-option">
      <v-icon>
        mdi-gift
      </v-icon>
      <div class="d-flex flex-grow-1 text-pre" v-if="getCurrentGroup.products">
        <v-chip
          label
          small
          :key="idx"
          v-for="(tag, idx) in getCurrentGroup.products"
          class="mr-1 mb-1 cr-tag-chip"
        >
          {{ tag }}
        </v-chip>
      </div>
    </div>
    <div class="cr-card-detail-option">
      <v-icon>
        mdi-comment-text-outline
      </v-icon>
      <div class="d-flex flex-grow-1 text-area">
        {{ getCurrentGroup.description }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters("customerGroup", ["getCurrentGroup"])
  },
  methods: {},
  created() {}
};
</script>

<style lang="scss" scoped>
.cr-card-detail-options {
  overflow-y: auto;
  flex-wrap: wrap;
  &.mini {
    .cr-card-detail-option {
      flex: 1 1 100%;
    }
  }
  .cr-card-detail-option {
    flex: 1 1 40%;
    display: flex;
    align-items: center;
    min-height: 44px;
    user-select: text;

    > i.v-icon {
      margin-right: 12px;
    }

    .text-area {
      white-space: pre-line;
    }
  }
}
</style>
