<template>
  <div class="pa-2">
    <v-row>
      <v-col cols="12" class="cr-memberType-wrapper py-0">
        <v-list-item class="pa-0">
          <v-list-item-content>
            <v-list-item-title class="mb-1 text-subtitle-2">
              <span v-text="$t('customer.isPublic')"></span>
            </v-list-item-title>
            <v-list-item-subtitle
              v-text="
                isPublic ? $t('customer.member_N') : $t('customer.member_Y')
              "
            />
          </v-list-item-content>
          <v-list-item-action>
            <v-switch hide-details inset v-model="isPublic" />
          </v-list-item-action>
        </v-list-item>
      </v-col>
    </v-row>
    <v-row v-show="!isPublic" class="ma-0 mt-3">
      <v-col class="pa-2 cr-sub-wrapper" cols="12">
        <Privilege
          ref="member"
          :type="'member'"
          :title="$t('board.구성원')"
          :placeholder="$t('board.49')"
          :members="members"
          :deleteList.sync="deleteMemberList"
          @updateMember="updateMember"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Privilege from "./admin/Privilege.vue";
import i18n from "@/_locales";
import { mapActions, mapGetters } from "vuex";
import { fetchCustomerGroupMembers } from "@/customer/api/group.api";

export default {
  components: { Privilege },
  data() {
    return {
      isPublic: true,
      members: [],
      deleteMemberList: []
    };
  },
  computed: {
    ...mapGetters("customerGroup", ["getCurrentGroup"])
  },
  async created() {
    await this.initData();
  },
  methods: {
    ...mapActions("snackbar", ["openSnackbar"]),
    async initData() {
      const { id, isPublic } = this.getCurrentGroup;
      this.isPublic = isPublic;
      if (isPublic) {
        return;
      }
      const { data } = await fetchCustomerGroupMembers(id);
      data.forEach(member => {
        member["groupId"] = id;
        member["isNew"] = false;
        member["isUpdated"] = false;
      });
      this.members = data;
    },
    updateMember(type, item) {
      // 중복확인
      const memberCheck = this.members.filter(
        ({ memberId }) => memberId === item.userId
      );
      const { length: memberExist } = memberCheck;

      let message = "";
      let trigger = false;

      if (type === "member") {
        // 사용자 변경일 경우
        if (memberExist > 0) trigger = true;
        message =
          memberExist > 0
            ? i18n.t("board.41") // 이미 추가됨
            : "";
      }

      if (trigger) {
        return this.openSnackbar({
          message,
          type: "VALIDATION"
        });
      }

      // 2. 삭제목록 멤버일 경우 -> 다시 되돌려 준다.
      const deleteList = this.deleteMemberList;
      //type == "member" ? this.deleteMemberList : this.deleteAdminList;
      const index = deleteList.findIndex(m => m.memberId === item.userId);
      if (index >= 0) {
        const target = deleteList.splice(index, 1);
        return (this.members = [...target, ...this.members]);
      }

      const member = {
        memberId: item.userId,
        user: {
          accountName: item.name
        },
        privilegeType: "READ_WRITE",
        isNew: true,
        isUpdated: false
      };

      if (type === "member") {
        this.members.unshift(member);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.cr-memberType-wrapper,
.v-input.v-textarea {
  .v-input__control {
    .v-input__slot {
      padding-right: 13px;
    }
    textarea {
      margin-bottom: 10px;
    }
  }
}
.cr-sub-wrapper {
  background-color: #f5f8fa !important;
  border: thin solid rgba(0, 0, 0, 0.12);
  border-width: 1px 0 1px 0px;
  //border-bottom: thin solid rgba(0, 0, 0, 0.12);
  .cr-no-data {
    text-align: center;
  }
}
</style>
