<template>
  <div class="cr-card-detail-options">
    <div class="cr-card-detail-option">
      <v-icon>
        mdi-domain
      </v-icon>
      <v-row>
        <v-col cols="12" sm="12">
          <TextField :label="$t('customer.name')" :value.sync="groupName" />
        </v-col>
      </v-row>
    </div>
    <div class="cr-card-detail-option">
      <v-icon>
        mdi-web
      </v-icon>
      <v-row>
        <v-col cols="12" sm="12">
          <TextField :label="$t('board.도메인')" :value.sync="domain" />
        </v-col>
      </v-row>
    </div>
    <div class="cr-card-detail-option">
      <v-icon>
        mdi-link
      </v-icon>
      <v-row>
        <v-col cols="12" sm="12">
          <TextField :label="$t('contact.홈페이지')" :value.sync="url" />
        </v-col>
      </v-row>
    </div>
    <div class="cr-card-detail-option">
      <v-icon>
        mdi-map-marker-outline
      </v-icon>
      <v-row>
        <v-col cols="5" sm="6">
          <TextField :label="$t('contact.우편번호')" :value.sync="post" />
        </v-col>
        <v-col cols="6" sm="6">
          <TextField :label="$t('contact.시도')" :value.sync="locality" />
        </v-col>
        <v-col cols="12" sm="12">
          <TextField :label="$t('contact.주소')" :value.sync="streetAddress" />
        </v-col>
      </v-row>
    </div>
    <div class="cr-card-detail-option">
      <v-icon>
        mdi-phone
      </v-icon>
      <v-row>
        <v-col cols="12" sm="12">
          <TextField :label="$t('contact.전화번호')" :value.sync="tel" />
        </v-col>
      </v-row>
    </div>
    <div class="cr-card-detail-option mt-2">
      <v-icon>
        mdi-gift
      </v-icon>
      <v-row>
        <v-col cols="12" sm="12">
          <v-combobox
            dense
            hide-details
            autocomplete="off"
            v-model="products"
            chips
            clearable
            multiple
            append-icon=""
          >
            <template v-slot:label>
              <span>{{ $t("customer.products") }}</span>
            </template>
            <template v-slot:selection="{ attrs, item, select, selected }">
              <v-chip
                v-bind="attrs"
                :input-value="selected"
                close
                @click="select"
                @click:close="remove(item)"
              >
                <strong>{{ item }}</strong>
              </v-chip>
            </template>
          </v-combobox>
        </v-col>
      </v-row>
    </div>
    <div class="cr-card-detail-option">
      <v-icon>
        mdi-comment-text-outline
      </v-icon>
      <v-row>
        <v-col cols="12" sm="12">
          <v-textarea
            dense
            hide-details
            autocomplete="off"
            tabindex="1"
            ref="description"
            :placeholder="$t('customer.description')"
            v-model="description"
          ></v-textarea>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TextField from "@/customer/views/components/list/common/TextField.vue";
import { fetchCustomerGroupMembers } from "@/customer/api/group.api";
export default {
  components: { TextField },
  computed: {
    ...mapGetters("customerGroup", ["getCurrentGroup"])
  },
  data() {
    return {
      id: 0,
      groupName: "",
      domain: "",
      url: "",
      tel: "",
      post: "",
      locality: "",
      streetAddress: "",
      products: [],
      description: "",
      isPublic: true,
      members: []
    };
  },
  async created() {
    const group = this.getCurrentGroup;
    const { data } = await fetchCustomerGroupMembers(group.id);
    data.forEach(member => {
      member["groupId"] = group.id;
      member["isNew"] = false;
      member["isUpdated"] = false;
    });

    this.members = data;
    this.id = group.id;
    this.parentId = group.parentId;
    this.groupName = group.groupName;
    this.domain = group.domain ?? "";
    this.url = group.url ?? "";
    this.tel = group.tel ?? "";
    this.post = group.post ?? "";
    this.locality = group.locality ?? "";
    this.streetAddress = group.streetAddress ?? "";
    this.products = group.products;
    this.description = group.description ?? "";
    this.isPublic = group.isPublic;
  },
  methods: {
    remove(item) {
      this.products.splice(this.products.indexOf(item), 1);
    }
  }
};
</script>

<style lang="scss" scoped>
.cr-card-detail-options {
  overflow: hidden;
  flex-wrap: wrap;
  &.mini {
    .cr-card-detail-option {
      flex: 1 1 100%;
    }
  }
  .cr-card-detail-option {
    flex: 1 1 40%;
    display: flex;
    align-items: center;
    min-height: 48px;
    user-select: text;

    > i.v-icon {
      margin-right: 12px;
    }
  }
}
</style>
