<template>
  <div class="cr-card-detail-header">
    <!-- 메일 쓰기 -->
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-hover v-slot="{ hover }">
          <v-btn
            icon
            :color="hover ? 'primary' : ''"
            v-bind="attrs"
            v-on="on"
            @click="openMailWritePopup"
          >
            <v-icon>mdi-email-edit</v-icon>
          </v-btn>
        </v-hover>
      </template>
      <span>{{ $t("contact.메일쓰기") }}</span>
    </v-tooltip>

    <!-- 일정 잡기 -->
    <v-tooltip bottom v-if="false">
      <template v-slot:activator="{ on, attrs }">
        <v-hover v-slot="{ hover }">
          <v-btn icon :color="hover ? 'primary' : ''" v-bind="attrs" v-on="on">
            <v-icon>mdi-calendar-edit</v-icon>
          </v-btn>
        </v-hover>
      </template>
      <span>일정 잡기</span>
    </v-tooltip>

    <v-spacer />

    <ModifyBtn v-if="hasWritePermission" v-on="$listeners" />
  </div>
</template>

<style lang="scss" scoped>
.cr-card-detail-header::v-deep {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  min-height: 40px;
  margin-bottom: 8px;

  .v-btn {
    margin-right: 4px !important;
  }
}
</style>

<script>
import { mapActions, mapGetters } from "vuex";
import ModifyBtn from "./ModifyBtn.vue";

export default {
  components: { ModifyBtn },
  computed: {
    ...mapGetters("customerCardDetail", ["detail"]),
    ...mapGetters("customerGroup", ["getCurrentGroup"]),
    hasWritePermission() {
      const group = this.getCurrentGroup;
      // 그룹이 없거나 그룹이 삭제 상태면 편집 X
      if (!group || group.isDeleted || group.customerGroupType === "TRASH") {
        return false;
      }
      return group.hasPermission;
    }
  },
  methods: {
    ...mapActions("mailRoute", ["routeMailWrite"]),
    openMailWritePopup() {
      this.routeMailWrite({
        t: 10,
        is: [this.detail.id]
      });
    }
  }
};
</script>
