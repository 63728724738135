<template>
  <div class="d-flex flex-grow-1" style="background-color:#f9f9f9">
    <div class="cr-customer-right-wrap pa-3">
      <MemoWrite />
      <History :historyList="getHistoryList" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MemoWrite from "./MemoWrite";
import History from "./history/index";

export default {
  components: { MemoWrite, History },
  computed: {
    ...mapGetters("customerHistory", ["getHistoryList"])
  }
};
</script>

<style lang="scss" scoped>
.cr-customer-right-wrap {
  position: relative;
  width: 650px;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}
</style>
