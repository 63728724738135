<template>
  <div class="cr-card-detail">
    <!-- 보기모드 -->
    <GroupView v-if="!isEdit" />
    <!-- 수정모드 -->
    <GroupEdit ref="groupEdit" v-else />
  </div>
</template>

<script>
import GroupView from "./GroupView";
import GroupEdit from "./GroupEdit";

export default {
  props: {
    isEdit: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  components: { GroupView, GroupEdit }
};
</script>

<style lang="scss" scoped>
.cr-card-detail {
  min-height: inherit;
  border-radius: 8px;
}
</style>
