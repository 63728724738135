<template>
  <v-card elevation="1" class="mb-3">
    <v-container>
      <div class="d-flex">
        <!-- 작성자 -->
        <strong class="memo-row body-2 black--text" v-text="item.accountName" />
        <span class="memo-row ml-2 body-2 grey--text" v-text="item.email" />

        <!-- 수정, 삭제 버튼 -->
        <div class="d-flex ml-2" v-if="item.userId === getUserInfo.id">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                icon
                v-bind="attrs"
                v-on="on"
                @click.prevent="openUpdateDialog(item)"
              >
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>수정</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                icon
                v-bind="attrs"
                v-on="on"
                @click.prevent="deleteMemo(item)"
              >
                <v-icon small>mdi-trash-can</v-icon>
              </v-btn>
            </template>
            <span>삭제</span>
          </v-tooltip>
        </div>
        <v-spacer />

        <!-- 등록일 -->
        <span
          class="memo-row caption"
          v-text="convertDateFromMillis(item.createdTimeMilli)"
        />
      </div>

      <!-- 내용 -->
      <div
        v-html="item.content"
        class="memo-contents text-wrap body-2 black--text mt-2"
        @click="viewMemo(item)"
      />

      <!-- 첨부 파일 -->
      <div v-if="item.refFilePath" class="d-flex mt-1">
        <v-icon small>
          mdi-paperclip
        </v-icon>
        <a class="ml-2" href="#" @click.prevent="downloadAttach(item)">
          {{ item.refFileName }}
        </a>
      </div>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import moment from "moment";
import i18n from "@/_locales";
import { MEMO_DIALOG_TYPES } from "@/customer/constant/constants";
import { downloadAttach } from "@/customer/api/memo.api";

export default {
  props: {
    idx: {
      type: Number,
      default: 0
    },
    length: {
      type: Number,
      default: 0
    },
    item: {
      type: Object
    }
  },
  computed: {
    ...mapGetters("auth", ["getUserInfo"])
  },
  created() {},
  mounted() {},
  methods: {
    ...mapActions("confirm", ["confirm"]),
    ...mapActions("customerHistory", ["deleteCustomerMemo"]),
    ...mapMutations("customerDialog", ["SET_DIALOG"]),
    convertDateFromMillis(millis) {
      return moment(millis).format("YYYY.MM.DD (dd) HH:mm");
    },
    /* 다이얼로그 다른 컴포넌트로 띄워야 함 */
    viewMemo(memo) {
      this.SET_DIALOG({
        title: i18n.t("customer.memo.title"),
        type: "customerMemo",
        params: {
          mode: MEMO_DIALOG_TYPES.VIEW,
          groupId: memo.groupId,
          contentType: memo.contentType,
          memo: memo
        },
        showButtons: false
      });
    },
    async openUpdateDialog(memo) {
      await this.SET_DIALOG({
        title: i18n.t("customer.memo.update"),
        type: "customerMemo",
        params: {
          mode: MEMO_DIALOG_TYPES.UPDATE,
          groupId: memo.groupId,
          contentType: memo.contentType,
          memo: memo
        }
      });
    },
    deleteMemo(memo) {
      this.confirm({
        message: i18n.t("customer.confirm.memo", {
          action: i18n.t("common.삭제")
        }),
        callback: async () => {
          // 메모 삭제
          await this.deleteCustomerMemo(memo);
        },
        showCloseBtn: true
      });
    },
    async downloadAttach(item) {
      const response = await downloadAttach(item.id);
      if (!response) {
        return false;
      }
      const blob = new Blob([response.data], {
        type: response.headers["content-type"]
      });
      const fileUrl = URL.createObjectURL(blob);
      // 브라우저 다운로드 기능 호출
      const link = document.createElement("a");
      link.href = fileUrl;
      link.download = item.refFileName;
      link.click();
      // URL 객체 해제
      URL.revokeObjectURL(fileUrl);
    }
  }
};
</script>

<style lang="scss" scoped>
.memo-row {
  min-height: 24px;
  line-height: 24px;
}

.memo-contents {
  display: -webkit-box; /* 플렉스 박스 사용 */
  -webkit-box-orient: vertical; /* 플렉스 박스 방향을 수직으로 설정 */
  overflow: hidden; /* 넘치는 텍스트를 숨김 */
  text-overflow: ellipsis; /* 넘치는 텍스트를 줄 줄임 기호(...)로 표시 */
  -webkit-line-clamp: 3; /* 표시할 행의 수 설정 (여기서는 3줄) */
  max-height: 4.5em; /* 라인 높이 * 줄 수 (여기서는 1.5em * 3) */
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
</style>
