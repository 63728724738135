<template>
  <div class="fill-height align-content-center customer-home-container">
    <div class="text-center" style="margin-top:240px;">
      팀 전체가 최신 고객 연락처를 쉽게 확인하고 공유하세요.
      <br />
      메모 작성과 메일 통합 관리도 한 곳에서 가능합니다.
      <br />
      <div class="mt-3">
        주소록에 읽기, 편집 권한이 없다면
        <a @click="showCustomerAdminList()">주소록 관리자</a>에게 문의하세요.
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import i18n from "@/_locales";

export default {
  data() {
    return {
      shareFlag: false,
      adminList: []
    };
  },
  computed: {
    ...mapGetters("auth", ["getUserInfo"]),
    ...mapGetters("customerGroup", ["getRootGroup", "getCustomerAdminList"])
  },
  mounted() {
    this.adminList = this.getCustomerAdminList;
  },
  methods: {
    ...mapMutations("customerDialog", ["SET_DIALOG"]),
    ...mapActions("snackbar", ["openSnackbar"]),
    showCustomerAdminList() {
      this.SET_DIALOG({
        title: i18n.t("customer.admin"),
        type: "customerAdmin",
        showButtons: false
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.customer-home-container {
  background-image: url(../../../../../commons/assets/customer_home.webp);
  background-position: center center;
}
</style>
