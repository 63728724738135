<template>
  <div class="d-flex">
    <div class="cr-customer-left-wrap px-5 py-4 fill-height">
      <GroupHeader
        :title="$t('customer.detail.group')"
        :showBtn="getHasWritePermission && !isMemberEdit && !isDeleted"
        @updateGroup="updateGroup"
        :isEdit.sync="isGroupEdit"
        :btnText="$t('common.수정')"
      />
      <Group ref="groupDivision" :isEdit="isGroupEdit" />

      <v-divider class="my-6" style="border-color:rgba(0, 0, 0, 0.06);" />

      <MemberHeader
        :title="$t('customer.detail.member')"
        :showBtn="getHasWritePermission && !isGroupEdit && !isDeleted"
        :isEdit.sync="isMemberEdit"
        @updateMember="updateMember"
        :btnText="$t('common.수정')"
      />
      <Member ref="memberDivision" :isEdit="isMemberEdit" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import GroupHeader from "./detail/group/GroupHeader.vue";
import Group from "./detail/group/index";
import MemberHeader from "./detail/member/MemberHeader.vue";
import Member from "./detail/member/index";
import i18n from "@/_locales";
import { isBlank } from "@/commons/utils/validation";
import { fetchDuplicationCheck } from "@/customer/api/group.api";

export default {
  components: { MemberHeader, GroupHeader, Member, Group },
  data() {
    return {
      isGroupEdit: false,
      isMemberEdit: false
    };
  },
  watch: {
    getCurrentGroup() {
      this.isGroupEdit = false;
      this.isMemberEdit = false;
    }
  },
  computed: {
    ...mapGetters("customerGroup", [
      "getHasWritePermission",
      "getCurrentGroup"
    ]),
    isDeleted() {
      const { isDeleted } = this.getCurrentGroup;
      return isDeleted;
    }
  },
  methods: {
    ...mapActions("snackbar", ["openSnackbar"]),
    ...mapActions("customerGroup", ["updateCustomerGroup"]),
    async updateGroup() {
      const groupData = this.$refs.groupDivision.$refs.groupEdit.$data;
      const duplicationCheck = await this.groupValidation(groupData);
      if (!duplicationCheck) {
        return false;
      }
      const result = await this.updateCustomerGroup(groupData);
      if (result) {
        this.isGroupEdit = false;
      }
    },
    async updateMember() {
      let groupData = JSON.parse(JSON.stringify(this.getCurrentGroup));
      const { isPublic, members } = this.$refs.memberDivision.$refs.memberEdit;

      if (!this.memberValidation(isPublic, members)) {
        await this.openSnackbar({
          message: i18n.t("customer.validate.member"),
          type: "ERROR"
        });
        return false;
      }

      groupData.isPublic = isPublic;
      groupData.members = members;
      const result = await this.updateCustomerGroup(groupData);
      if (result) {
        this.isMemberEdit = false;
      }
    },
    async groupValidation(group) {
      if (!group) {
        return false;
      }
      // 1. 기본 유효성 체크
      if (isBlank(group.groupName)) {
        return false;
      }
      // 2. 기존 데이터와 변경여부 체크후 서버와 그룹네임, 도메인 체크
      const originData = this.getCurrentGroup;
      const groupNameChanged = originData.groupName !== group.groupName;
      const domainChanged = originData.domain !== group.domain;
      if (groupNameChanged || domainChanged) {
        const { data } = await fetchDuplicationCheck({
          groupName: group.groupName,
          domain: group.domain
        });

        if (data.isNameExists && groupNameChanged) {
          // 그룹명 중복
          await this.openSnackbar({
            message: i18n.t("customer.validate.duplicated"),
            type: "ERROR"
          });
          return false;
        }
        if (data.isDomainExists && domainChanged) {
          // 도메인 중복
          await this.openSnackbar({
            message: i18n.t("customer.validate.domain"),
            type: "ERROR"
          });
          return false;
        }
      }
      return true;
    },
    memberValidation(isPublic, members) {
      return !(!isPublic && members.length < 1);
    }
  }
};
</script>

<style lang="scss" scoped>
.cr-customer-left-wrap {
  width: 400px;
  overflow-y: auto;
  background-color: #fff;
  border-right: thin solid rgba(0, 0, 0, 0.08);
}
</style>
