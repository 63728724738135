<template>
  <div class="cr-card-detail-header">
    <span class="text-subtitle-2">{{ title }}</span>
    <div>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on">
            {{ permissionIcon }}
          </v-icon>
        </template>
        <span>{{ tooltip }}</span>
      </v-tooltip>
    </div>
    <v-spacer />
    <div v-if="!isEdit">
      <v-btn
        v-if="showBtn"
        small
        elevation="0"
        color="primary"
        @click="e => changeEditStatus()"
      >
        {{ btnText }}
      </v-btn>
    </div>
    <div v-else>
      <v-btn small elevation="0" color="primary" @click="$emit('updateMember')">
        {{ btnText }}
      </v-btn>
      <v-btn
        v-if="isEdit"
        small
        elevation="0"
        outlined
        color="grey"
        class="ml-2"
        @click="changeEditStatus()"
      >
        취소
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import i18n from "@/_locales";
import { fetchCustomerGroupMembers } from "@/customer/api/group.api";

export default {
  props: {
    title: {
      type: String,
      required: true
    },
    showBtn: {
      type: Boolean,
      required: false,
      default: true
    },
    isEdit: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      groupId: 0,
      expanded: false,
      members: []
    };
  },
  created() {
    this.initData();
  },
  computed: {
    ...mapGetters("customerGroup", ["getCurrentGroup"]),
    btnText() {
      return this.isEdit ? "저장" : "수정";
    },
    isPublic() {
      return this.members.length <= 0;
    },
    permissionIcon() {
      return this.isPublic ? "mdi-lock-open" : "mdi-lock";
    },
    tooltip() {
      if (this.isPublic) {
        return i18n.t("customer.public");
      }

      let str = i18n.t("customer.restricted");
      str += ` (${i18n.t("customer.member.amount")} ${this.members.length})`;
      return str;
    }
  },
  watch: {
    getCurrentGroup(group) {
      if (this.groupId !== group.id) {
        this.groupId = group.id;
        this.initData();
      }
    }
  },
  methods: {
    async initData() {
      const { id, isPublic } = this.getCurrentGroup;
      if (id < 0 || isPublic) {
        this.members = [];
        return;
      }
      const { data } = await fetchCustomerGroupMembers(id);
      this.members = data;
    },
    changeEditStatus() {
      return this.$emit("update:isEdit", !this.isEdit);
    }
  }
};
</script>

<style lang="scss" scoped>
.cr-card-detail-header::v-deep {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
</style>
