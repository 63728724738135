<template>
  <div class="d-flex flex-grow-1">
    <div class="cr-customer-contact-wrapper">
      <Toolbar
        :selectedItems="selectedItems"
        @deleteCardList="deleteCardList"
        @print="print"
        @merge="merge"
        @clearSelectedItems="clearSelectedItems"
        @openMailWritePopup="openMailWritePopup"
        :showConsonant.sync="showConsonant"
        @toggleCheckbox="toggleCheckbox"
        @openRestoreCardDialog="openRestoreCardDialog"
      />
      <v-divider />
      <div
        class="cr-container ch-2 fill-height"
        :class="`cr-container-${this.$vuetify.breakpoint.name}`"
      >
        <Split
          :selectedItems.sync="selectedItems"
          @clearSelectedItems="clearSelectedItems"
          @selectedItemCancel="selectedItemCancel"
        />
      </div>

      <!-- 초성 필터 -->
      <v-card
        v-if="showConsonant"
        class="cr-consonant"
        v-click-outside="() => (this.showConsonant = false)"
      >
        <v-list dense mandatory class="px-2">
          <v-btn-toggle
            v-model="consonantSelected"
            mandatory
            tile
            group
            color="deep"
          >
            <v-btn
              v-for="consonant in consonantList"
              :key="consonant.label"
              small
              text
              class="cr-consonant-btn"
              @click="() => clickConsonant(consonant)"
            >
              {{ consonant.label }}
            </v-btn>
          </v-btn-toggle>
        </v-list>
      </v-card>
    </div>
  </div>
</template>

<script>
import i18n from "@/_locales";
import { mapActions, mapGetters, mapMutations } from "vuex";
import Toolbar from "@/customer/views/components/list/Toolbar.vue";
import Split from "./split";

export default {
  components: { Split, Toolbar },
  created() {
    // url로 들어왔을때 page값 설정
    this.page = this.getPage;
  },
  computed: {
    ...mapGetters("customerCard", [
      "getHeaders",
      "getCardList",
      "getTotalCount",
      "isLoading"
    ]),
    ...mapGetters("customerRoute", ["getRouteInfo", "getPage"]),
    ...mapGetters("customerGroup", ["getCurrentGroup"]),
    ...mapGetters("selectBox", ["isShowBox", "getSelectBoxItems"])
  },
  watch: {
    getCardList() {
      this.selectedItems = [];

      if (!this.isUseSelectBox) return;
      // selectBox사용 시 selectBox에 있는 item 현재 컴포넌트 selectItems에 추가
      this.getSelectBoxItems.forEach(boxItem => {
        const index = this.getCardList.findIndex(
          listItem => listItem.id === boxItem.id
        );
        if (index >= 0) this.selectedItems.push(boxItem);
      });
    },
    getPage(page) {
      this.page = page;
    },
    getRouteInfo(nextCardListInfo, prevCardListInfo) {
      // 그룹변경 때 parameter들(정렬, 초성) 초기화
      // 그룹에서 페이지이동이나, 정렬, 초성등 변경일 경우는 초기화 진행 x
      if (nextCardListInfo.groupId === prevCardListInfo.groupId) return;
      const { customerGroupId, sort, dir } = nextCardListInfo;
      this.isNew = customerGroupId === "new";
      this.options.sortBy[0] = sort || "userName";
      this.options.sortDesc[0] = dir === "desc";
      this.consonantSelected = undefined;
      this.clearSelectedItems();
    },
    selectedItems: function(nextSelectedItems) {
      if (nextSelectedItems.length > 0) {
        if (this.isUseSelectBox) {
          this.SET_SHOW(true);
        }
        if (nextSelectedItems.length === this.getCardList.length)
          this.toggleCheck = true;

        const selectedItems = nextSelectedItems
          .map(({ id, userName }) => ({
            id,
            userName
          }))
          .sort((p, n) => p.userName.localeCompare(n.userName));
        this.id = selectedItems[0].id;
      } else {
        this.id = null;
        this.toggleCheck = false;
      }
    }
  },
  methods: {
    ...mapMutations("customerCardDetail", ["SET_CARD_DETAIL"]),
    ...mapMutations("customerDialog", ["SET_DIALOG"]),
    ...mapMutations("selectBox", [
      "SET_SHOW",
      "SET_SELECT_ITEM",
      "SET_SELECT_ITEM_All",
      "RESET_SELECT_BOX"
    ]),
    ...mapActions("mailRoute", ["routeMailWrite"]),
    ...mapActions("confirm", ["confirm"]),
    ...mapActions("positioningMenu", [
      "positioningMenu",
      "closePositioningMenu"
    ]),
    ...mapActions("customerCard", ["getContactDetail", "delete"]),
    // item 선택 해제(selectBox에서 사용)
    selectedItemCancel(item) {
      let index = this.selectedItems.findIndex(
        selectedItem => selectedItem.id === item.id
      );

      if (index >= 0) {
        this.selectedItems.splice(index, 1);
      }
    },

    toggleCheckbox(checked) {
      if (checked) {
        this.selectedItems = this.getCardList;
      } else {
        this.selectedItems = [];
      }

      // selectBox사용시 selectBox에 체크된item 추가
      if (!this.isUseSelectBox) return;
      this.SET_SELECT_ITEM_All({ checked, list: this.getCardList });
    },

    // Dialog 닫기
    closeDialog() {
      this.dialog.showDialog = false;
    },

    deleteCardList(e, item) {
      const { id } = this.getCurrentGroup;
      let message;
      const cardIdList = [];

      // selectBox사용에 따른 selectItems설정
      const selectedItems = this.isUseSelectBox
        ? this.getSelectBoxItems
        : this.selectedItems;

      if (selectedItems.length > 0) {
        message = i18n.t("customer.confirm.deleteAll");
        selectedItems.map(({ id }) => cardIdList.push(id));
      } else {
        message = i18n.t("customer.confirm.delete");
        cardIdList.push(item.id);
      }
      this.confirm({
        message,
        callback: () => {
          this.delete({ groupId: id, cardIdList: cardIdList });
          this.clearSelectedItems();
        },
        showCloseBtn: true
      });
    },
    // 합치기
    async merge() {
      // selectBox사용에 따른 selectItems설정
      const selectedItems = this.isUseSelectBox
        ? this.getSelectBoxItems
        : this.selectedItems;

      const cardIdList = selectedItems.map(({ id }) => id);
      const mergeCard = await this.getContactDetailList(cardIdList);
      mergeCard.id = this.id;

      this.SET_DIALOG({
        title: i18n.t("contact.43"),
        type: "mergeCard",
        params: {
          mergeCard
        },
        showExpand: true,
        confirmCallbackFunc: () => {
          this.clearSelectedItems();
        }
      });
    },
    clearSelectedItems() {
      this.selectedItems = [];

      // selectBox 사용시 초기화
      if (!this.isUseSelectBox) return;
      this.RESET_SELECT_BOX();
    },
    // 메일 쓰기 팝업 호출
    async openMailWritePopup(list) {
      await this.routeMailWrite({
        t: 10,
        is: list.map(({ id }) => id)
      });
    },

    // 인쇄
    print() {
      const headers = [];
      for (const header of this.getHeaders) {
        headers.push({ text: header.text, value: header.value });
      }
      const { groupId: id, actionObj } = this.getRouteInfo;
      const { sort, dir, consonant, pageSize } = actionObj;

      window.open(
        `/#/customer/print/${id}/1/${JSON.stringify({
          headers,
          sort,
          dir,
          consonant,
          pageSize
        })}`,
        "cardprint",
        "width=900,height=600,location=no,directories=no,resizable=no,status=no,toolbar=yes,menubar=no,scrollbars=yes"
      );
    },
    // 연락처 복구 Dialog 열기
    openRestoreCardDialog() {
      this.SET_DIALOG({
        title: i18n.t("customer.restore.title"),
        type: "customerCardRestore",
        params: {
          selectedItems: this.isUseSelectBox
            ? this.getSelectBoxItems
            : this.selectedItems
        },
        confirmCallbackFunc: () => {
          this.clearSelectedItems();
        }
      });
    },
    clickConsonant(item) {
      this.page = 1;
      this.pushRoute({ page: 1, consonant: item.value });
    },

    pushRoute({ page, consonant }) {
      const {
        contactGroupId: id,
        page: infoPage,
        actionObj = {}
      } = this.getRouteInfo;
      let customerGroupId = id;
      const { sortBy, sortDesc } = this.options;
      let newActionObj = { ...actionObj };

      if (sortBy[0]) {
        newActionObj.sort = sortBy[0];
        newActionObj.dir = sortDesc[0] ? "desc" : "asc";
      }

      if (consonant) {
        newActionObj.consonant = consonant;
      } else if (this.consonantSelected > 0 && consonant) {
        newActionObj.consonant = this.consonantList[
          this.consonantSelected
        ].value;
      } else {
        if (!consonant) {
          delete newActionObj.consonant;
        }
      }

      this.$router.push({
        name: "customer_list_action",
        params: {
          customerGroupId: customerGroupId,
          page: page || infoPage,
          actionObj: JSON.stringify(newActionObj)
        }
      });
    }
  },
  data() {
    return {
      isUseSelectBox: true,
      selectedItems: [],
      page: 1,
      pageCount: 0,
      toggleCheck: false,
      loading: true,
      options: {
        mustSort: true,
        sortBy: ["userName"],
        sortDesc: [false]
      },
      consonantSelected: undefined,
      consonantList: [
        { label: "전체" },
        { label: "가", value: "ㄱ" },
        { label: "나", value: "ㄴ" },
        { label: "다", value: "ㄷ" },
        { label: "라", value: "ㄹ" },
        { label: "마", value: "ㅁ" },
        { label: "바", value: "ㅂ" },
        { label: "사", value: "ㅅ" },
        { label: "아", value: "ㅇ" },
        { label: "자", value: "ㅈ" },
        { label: "차", value: "ㅊ" },
        { label: "카", value: "ㅋ" },
        { label: "타", value: "ㅌ" },
        { label: "파", value: "ㅍ" },
        { label: "하", value: "ㅎ" },
        { label: "A-Z", value: "A" },
        { label: "0-9", value: "0" }
      ],
      selectedCount: 0,
      sortBy: "userName",
      sortDesc: false,
      dialog: {
        card: {},
        showDialog: false,
        parentFolder: null,
        folder: null
      },
      isNew: false,
      showConsonant: false,
      helperLabel: "그룹에 연락처 $n개 이동"
    };
  }
};
</script>

<style lang="scss" scoped>
.cr-customer-contact-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  background-color: white;
}

/* 초성 검색 */
.cr-consonant ::v-deep {
  z-index: 2;
  position: absolute;
  right: 112px;
  top: 36px;
  .v-item-group {
    z-index: 2;
    padding: 0px;
    background-color: #fff;
  }
  .v-item-group > .cr-consonant-btn {
    min-width: 0px;
    padding: 5px;
    margin: 0px;
    height: inherit;
  }
}
</style>
