<template>
  <v-dialog
    scrollable
    no-click-animation
    :persistent="persistent"
    :width="width"
    :max-width="width"
    v-model="show"
    @input="CLOSE_DIALOG"
  >
    <v-card>
      <v-card-title>
        <span class="text-h6 font-weight-bold" v-text="dialogTitle"></span>
        <v-spacer></v-spacer>
        <v-icon @click="CLOSE_DIALOG">mdi-close</v-icon>
      </v-card-title>
      <DialogContent
        :confirm.sync="confirm"
        :confirmDisabled.sync="confirmDisabled"
        :expand.sync="expand"
      ></DialogContent>
      <v-card-actions class="py-4">
        <v-btn v-if="isShowExpand" text link @click="expand = !expand">
          <v-icon
            class="mr-2"
            v-text="expand ? 'mdi-chevron-up' : 'mdi-chevron-down'"
          />
          {{ $t("contact.더보기") }}
        </v-btn>
        <v-spacer />
        <v-btn text link @click="CLOSE_DIALOG" v-if="isShowButtons">
          {{ getCancelBtn.text }}
        </v-btn>
        <v-btn
          color="primary"
          v-if="isShowButtons"
          :text="!isShowExpand"
          elevation="0"
          @click="confirm = true"
        >
          {{ getConfirmBtn.text }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import i18n from "@/_locales";
import { mapGetters, mapMutations } from "vuex";
import DialogContent from "@/customer/views/components/dialog/index";
export default {
  components: { DialogContent },
  data: () => ({
    show: false,
    persistent: false,
    // 확인 버튼
    confirm: false,
    // 확인 버튼 Disabled 여부
    confirmDisabled: false,
    expand: false
  }),
  watch: {
    showDialog(value) {
      this.show = value;
    },
    dialogType(value) {
      this.confirm = false;
      this.confirmDisabled = false;
      this.persistent = value === "addCard";
    }
  },
  computed: {
    ...mapGetters("customerDialog", [
      "dialogType",
      "dialogTitle",
      "showDialog",
      "isShowExpand",
      "isShowButtons",
      "getCancelBtn",
      "getConfirmBtn"
    ]),
    width() {
      switch (this.dialogType) {
        case "copyCard":
        case "customerSearch":
          return "900";
        case "customerMemo":
          return "600";
        case "customerCard":
          return "500";
        default:
          return "400";
      }
    }
  },
  methods: {
    ...mapMutations("customerDialog", ["CLOSE_DIALOG", "SET_SHOW_EXPAND"]),
    async updateMode() {
      this.showExpand = true;
      this.expand = true;
      this.type = "updateCard";
      this.headline = i18n.t("contact.11");
    }
  }
};
</script>
