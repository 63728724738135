<template>
  <v-row
    class="cr-list-trash-header pa-3 ma-0 red accent-3 white--text mb-3"
    v-if="isDeletedGroup"
  >
    <v-col cols="12" class="text-center py-2">
      <span class="mr-3" v-text="$t('customer.deleted.title')"></span>
      <!-- 삭제 취소 -->
      <v-btn
        v-if="isAdmin"
        small
        text
        height="30"
        outlined
        color="white"
        tile
        @click="openCancelConfirm"
        v-text="$t('customer.deleted.cancel')"
      >
      </v-btn>
    </v-col>
    <v-col cols="12" class="text-center py-1">
      <v-icon color="white" class="mr-2">mdi-information-outline</v-icon>
      <span v-text="deletedTime" class="text-body-2"></span>
    </v-col>
  </v-row>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { convertLastChangeDate } from "@/commons/utils/moment";
import i18n from "@/_locales";

export default {
  computed: {
    ...mapGetters("auth", ["getUserInfo"]),
    ...mapGetters("customerGroup", ["getCurrentGroup", "getCustomerAdminList"]),
    isDeletedGroup() {
      const { isDeleted } = this.getCurrentGroup;
      return !!isDeleted;
    },
    deletedTime() {
      const { deletedTimeMillis } = this.getCurrentGroup;
      let deleteTime = new Date(deletedTimeMillis);
      deleteTime.setDate(deleteTime.getDate() + 7);
      const result = convertLastChangeDate(deleteTime) * -1;

      return result > 0
        ? i18n.t("customer.deleted.text1", { value: result }) // ?일뒤 자동 삭제됨
        : i18n.t("customer.deleted.text2"); // 곧 자동 삭제됨
    },
    isAdmin() {
      // 고객주소록 관리자가 아니면, 복구 불가.
      const { id: userId } = this.getUserInfo;
      const adminList = this.getCustomerAdminList;
      return adminList.some(admin => admin.userDetail.userId === userId);
    }
  },
  methods: {
    ...mapActions("confirm", ["confirm", "disagree"]),
    ...mapActions("customerGroup", ["restoreCustomerGroup"]),
    openCancelConfirm() {
      const { id, groupName } = this.getCurrentGroup;
      this.confirm({
        headline: "",
        message: i18n.t("customer.confirm.group.restore", { value: groupName }),
        callback: async () => {
          await this.disagree();
          await this.restoreCustomerGroup(id);
        }
      });
    }
  }
};
</script>
