<template>
  <v-card elevation="1" class="mb-3">
    <v-container>
      <div class="d-flex">
        <v-spacer />

        <!-- 등록일 -->
        <span
          class="caption"
          v-text="convertDateFromMillis(item.createdTimeMilli)"
        />
      </div>

      <!-- 내용 -->
      <div
        v-html="convertDescription(item)"
        class="memo-contents text-wrap body-2 black--text mt-2"
      />
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";
import i18n from "@/_locales";

export default {
  props: {
    idx: {
      type: Number,
      default: 0
    },
    length: {
      type: Number,
      default: 0
    },
    item: {
      type: Object
    }
  },
  methods: {
    ...mapActions("confirm", ["confirm"]),
    ...mapActions("positioningMenu", ["closePositioningMenu"]),
    convertDateFromMillis(millis) {
      return moment(millis).format("YYYY.MM.DD (dd) HH:mm");
    },
    convertDescription(item) {
      const content = item.content;
      let contentObj;
      try {
        contentObj = JSON.parse(content);
      } catch (error) {
        console.error("Error parsing JSON:", error);
        return "";
      }
      return i18n.t(`customer.history.description.${contentObj.contentType}`, {
        name: item.accountName,
        groupName: contentObj.groupName,
        cardTitle: contentObj.cardName,
        actionType: i18n.t(
          `customer.history.actionType.${contentObj.actionType}`
        )
      });
    }
  }
};
</script>
