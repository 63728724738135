<template>
  <v-sheet class="cr-list-toggle-header flex-grow-1">
    <v-chip-group
      key="customer-view-toggle"
      class="ml-5"
      v-model="_toggle"
      mandatory
      column
      depressed
      active-class="primary text--accent-5"
    >
      <v-chip small :ripple="false" value="LIST" key="view-toggle-list">
        <v-icon left small>
          mdi-clipboard-list-outline
        </v-icon>
        {{ $t("customer.view.contact") }}
      </v-chip>
      <v-chip small :ripple="false" value="HISTORY" key="view-toggle-history">
        <v-icon left small>
          mdi-history
        </v-icon>
        {{ $t("customer.view.history") }}
      </v-chip>
    </v-chip-group>
  </v-sheet>
</template>

<script>
export default {
  props: {
    viewToggle: {
      type: String,
      default: "LIST",
      required: true
    }
  },
  computed: {
    _toggle: {
      get() {
        return this.viewToggle;
      },
      set(val) {
        return this.$emit("update:viewToggle", val);
      }
    }
  }
};
</script>
