<template>
  <div
    class="text-center align-content-center"
    style="height: 200px; width: 100%"
  >
    <span class="subtitle-1 grey--text">
      {{ text }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String
    }
  }
};
</script>
