<template>
  <div class="cr-card-detail-wrapper mb-3">
    <div class="cr-card-detail pa-3">
      <!-- 툴바 -->
      <Header @save="save" />
      <div class="cr-card-detail-options" :class="{ mini: isMini }">
        <component
          ref="comp"
          v-for="(comp, idx) in components"
          :key="idx"
          :is="comp"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cr-card-detail-wrapper::v-deep {
  .cr-card-detail {
    border-radius: 8px;
    border: thin solid rgba(0, 0, 0, 0.08);
    background-color: white;

    .cr-card-detail-options {
      overflow-y: auto;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      margin: 0px -3px;
      &.mini {
        .cr-card-detail-option {
          flex: 1 1 100%;
        }
      }
      .cr-card-detail-option {
        flex: 1 1 40%;
        display: flex;
        align-items: center;
        min-height: 48px;
        margin: 3px 3px;
        padding: 8px;
        border-radius: 8px;
        //border: thin solid rgba(0, 0, 0, 0.08);
        background: #fff;
        user-select: text;

        > i.v-icon {
          margin-right: 16px;
        }

        .cr-edit-wrapper {
          width: calc(100% - 50px);
        }
      }

      .v-text-field fieldset {
        border-radius: 8px;
      }
    }
  }
}
</style>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import Header from "./header";

export default {
  components: { Header },
  props: {
    components: {
      type: Object,
      default() {
        return {
          Name: () => import("./options/name"),
          //Group: () => import("./options/group"), // 고객사 주소록에서는 카드와 그룹이 1:1이므로 해제
          EmailList: () => import("./options/email"),
          Company: () => import("./options/company"),
          Phone: () => import("./options/phone"),
          Address: () => import("./options/address"),
          Homepage: () => import("./options/homepage"),
          Memo: () => import("./options/memo")
        };
      }
    }
  },
  computed: {
    ...mapGetters("customerCardDetail", ["detail", "isEdit"]),
    isMini() {
      return this.$vuetify.breakpoint.smAndDown;
    }
  },
  methods: {
    ...mapMutations("customerCardDetail", ["SET_IS_EDIT"]),
    ...mapActions("snackbar", ["openSnackbar"]),
    ...mapActions("customerCard", ["updateCard"]),
    ...mapActions("customerCardDetail", ["getContactDetail"]),
    async save() {
      if (!this.isEdit) return this.SET_IS_EDIT(true);

      try {
        let data = { id: this.detail.id, groupId: this.detail.groupId };
        this.$refs.comp.forEach(
          ref => (data = { ...data, ...(ref?.getData() ?? {}) })
        );

        await this.updateCard(data);
        await this.getContactDetail(data.id);
      } catch (e) {
        await this.openSnackbar({ message: e.message });
      }
    }
  }
};
</script>
