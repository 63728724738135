<template>
  <div class="history-wrap">
    <v-row dense>
      <v-col cols="12">
        <v-chip-group
          key="customer-history-toggle"
          class="ml-5 float-right"
          v-model="_historyType"
          mandatory
          column
          depressed
          active-class="primary text--accent-5"
          @change="changeHistoryType"
        >
          <v-chip small :ripple="false" value="ALL" key="view-all-list">
            <v-icon left small>
              mdi-check
            </v-icon>
            {{ $t("customer.view.all") }}
          </v-chip>
          <v-chip small :ripple="false" value="MEMO" key="view-memo-list">
            <v-icon left small>
              mdi-note-outline
            </v-icon>
            {{ $t("customer.view.memo") }}
          </v-chip>
          <v-chip small :ripple="false" value="HISTORY" key="view-history-list">
            <v-icon left small>
              mdi-history
            </v-icon>
            {{ $t("customer.view.history") }}
          </v-chip>
        </v-chip-group>
      </v-col>
    </v-row>
    <HistoryList
      v-if="historyList.length > 0"
      ref="historyListArea"
      :historyList="historyList"
      :canGoScrollTop.sync="canGoScrollTop"
    />
    <EmptyMessage v-else :text="$t('customer.message.noData')" />
    <v-btn
      v-if="showGoUpBtn"
      class="btn-top"
      fab
      dark
      x-small
      color="primary"
      @click="goHistoryScrollTop"
    >
      <v-icon>mdi-arrow-up-bold-circle</v-icon>
    </v-btn>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import EmptyMessage from "../../../common/EmptyMessage";
import HistoryList from "./HistoryList";

export default {
  components: { HistoryList, EmptyMessage },
  props: {
    historyList: {
      type: Array
    }
  },
  data() {
    return {
      canGoScrollTop: false,
      historyType: "ALL"
    };
  },
  computed: {
    ...mapGetters("customerHistory", ["getHistoryList", "getHistoryType"]),
    ...mapGetters("customerRoute", ["getRouteInfo"]),
    showGoUpBtn() {
      if (this.getHistoryList.length < 1) {
        return false;
      }
      return this.canGoScrollTop;
    },
    _historyType: {
      get() {
        return this.getHistoryType;
      },
      set(val) {
        this.SET_HISTORY_TYPE(val);
      }
    }
  },
  created() {},
  mounted() {},
  methods: {
    ...mapActions("customerHistory", ["loadHistoryList"]),
    ...mapMutations("customerHistory", ["SET_HISTORY_TYPE"]),
    goHistoryScrollTop() {
      this.$refs.historyListArea.$refs.historyArea.scrollTop = 0;
    },
    changeHistoryType(val) {
      const { groupId } = this.getRouteInfo;
      this.canGoScrollTop = false;
      this.loadHistoryList({
        groupId: groupId,
        page: 0,
        historyType: val,
        shouldReset: true
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.history-wrap {
  flex-grow: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}
.btn-top {
  position: absolute;
  left: 300px;
  bottom: 20px;
}
</style>
