<template>
  <div class="cr-card-detail-options">
    <v-list class="pa-0">
      <v-list-item v-for="(member, i) in members" :key="i" class="px-0">
        <v-list-item-content>
          <v-list-item-subtitle class="black--text">
            {{ username(member.user) }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-icon>
          <v-chip label small>{{
            $t(`customer.member.privilege.${member.privilegeType}`)
          }}</v-chip>
        </v-list-item-icon>
      </v-list-item>
    </v-list>
    <div v-if="isPublic" class="empty-message mt-5">
      전체 공개 주소록
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { fetchCustomerGroupMembers } from "@/customer/api/group.api";

export default {
  watch: {
    async getCurrentGroup() {
      await this.initData();
    }
  },
  data() {
    return {
      expanded: false,
      members: []
    };
  },
  created() {
    this.initData();
  },
  computed: {
    ...mapGetters("customerGroup", ["getCurrentGroup"]),
    isPublic() {
      return this.members.length <= 0;
    }
  },
  methods: {
    async initData() {
      const { id, isPublic } = this.getCurrentGroup;
      if (id < 0 || isPublic) {
        this.members = [];
        return;
      }
      const { data } = await fetchCustomerGroupMembers(id);
      this.members = data;
    },
    username(user) {
      let username = user.accountName;
      if (user.organizationName) {
        username += ` / ${user.organizationName}`;
      }
      if (user.jobGrade) {
        username += ` / ${user.jobGrade}`;
      }
      return username;
    }
  }
};
</script>

<style lang="scss" scoped>
.cr-card-detail-options {
  overflow-y: auto;
  flex-wrap: wrap;
  &.mini {
    .cr-card-detail-option {
      flex: 1 1 100%;
    }
  }
  .cr-card-detail-option {
    flex: 1 1 40%;
    display: flex;
    align-items: center;
    user-select: text;
  }
  .empty-message {
    color: rgba(0, 0, 0, 0.5);
    font-size: 14px;
    text-align: center;
  }
}
</style>
