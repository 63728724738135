<template>
  <v-container>
    <!-- 그룹 -->
    <v-select
      prepend-icon="mdi-folder-outline"
      ref="customerGroupList"
      v-model="targetGroupId"
      :items="customerGroupList"
      item-value="id"
      chips
      :label="$t('customer.restore.target')"
      required
      :rules="rule"
    >
      <template v-slot:selection="{ item }">
        <span>{{ item.groupName }}</span>
      </template>
      <template v-slot:item="{ item }">
        <span :style="`padding-left: ${item.depth * 10}px`">{{
          item.depth > 0 ? "└ " + item.groupName : item.groupName
        }}</span>
      </template>
    </v-select>
  </v-container>
</template>

<script>
import i18n from "@/_locales";
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  props: {
    confirm: {
      default: false,
      type: Boolean,
      description: "확인버튼"
    }
  },
  watch: {
    confirm: function(next) {
      if (next) {
        this.restoreCardFunc();
        this.$emit("update:confirm", false);
      }
    }
  },
  async mounted() {
    // 공용 주소록 그룹 리스트 조회
    this.customerGroupList = await this.getFlatCustomerGroupList();
    this.$refs["customerGroupList"].$refs.menu.updateDimensions();
    // cardList 에서 선택된 연락처 조회
    const { selectedItems } = this.getParams;
    this.selectedCardIdList = selectedItems.map(card => card.id);
  },
  data: () => ({
    targetGroupId: 0,
    customerGroupList: [],
    selectedCardIdList: [],
    rule: [v => !!v || i18n.t("customer.validate.restore")]
  }),
  computed: {
    ...mapGetters("mailDialog", ["params", "dialogType"]),
    ...mapGetters("customerDialog", ["getParams"])
  },
  methods: {
    ...mapMutations("customerDialog", ["CLOSE_DIALOG"]),
    ...mapMutations("selectBox", ["RESET_SELECT_BOX"]),
    ...mapActions("confirm", { confirmDialog: "confirm" }),
    ...mapActions("customerCard", ["restoreCustomerCard"]),
    ...mapActions("customerGroup", ["getFlatCustomerGroupList"]),
    restoreCardFunc() {
      const checkGroup = this.$refs.customerGroupList.validate(true);
      if (!checkGroup) {
        return;
      }
      this.confirmDialog({
        message: i18n.t("customer.confirm.restore"),
        callback: async () => {
          const result = await this.restoreCustomerCard({
            targetGroupId: this.targetGroupId,
            cardIdList: this.selectedCardIdList
          });
          if (result) {
            this.clearSelectedItems();
            this.CLOSE_DIALOG();
          }
        }
      });
    },
    clearSelectedItems() {
      this.RESET_SELECT_BOX();
    }
  }
};
</script>
