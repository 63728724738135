<template>
  <div>
    <div class="d-flex">
      <!-- 작성자 -->
      <strong class="memo-row body-1 black--text" v-text="accountName" />
      <span class="memo-row ml-2 body-2 grey--text" v-text="email" />
      <v-spacer />
      <!-- 등록일 -->
      <span
        class="memo-row caption"
        v-text="convertDateFromMillis(createdTimeMilli)"
      />
    </div>

    <!-- 내용 -->
    <div
      v-html="content"
      class="memo-contents text-wrap body-1 black--text mt-2"
    />

    <!-- 첨부 파일 -->
    <div v-if="refFilePath" class="d-flex mt-2">
      <v-icon small>
        mdi-paperclip
      </v-icon>
      <a class="ml-2" href="#" @click.prevent="downloadAttach(item)">
        {{ refFileName }}
      </a>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { downloadAttach } from "@/customer/api/memo.api";
import { MEMO_DIALOG_TYPES } from "@/customer/constant/constants";
import moment from "moment/moment";

export default {
  data() {
    return {
      persistent: true,
      id: 0,
      content: "",
      userId: 0,
      email: "",
      userName: "",
      accountName: "",
      groupId: 0,
      contentType: "CARD",
      refFileType: null,
      refFilePath: null,
      refFileName: null,
      attach: null,
      editorHeight: 300,
      createdTimeMilli: 0
    };
  },
  computed: {
    ...mapGetters("customerDialog", ["getParams"]),
    hasAttach() {
      return !!(
        this.getParams.mode === MEMO_DIALOG_TYPES.VIEW && this.refFileName
      );
    }
  },
  created() {
    this.initData();
  },
  methods: {
    ...mapActions("snackbar", ["openSnackbar"]),
    ...mapActions("confirm", { confirmDialog: "confirm" }),
    async initData() {
      const { groupId, contentType, memo } = this.getParams;

      this.groupId = groupId;
      this.userId = memo.userId;
      this.username = memo.userName;
      this.email = memo.email;
      this.accountName = memo.accountName;
      this.content = memo.content;
      this.contentType = contentType;
      this.refFileType = memo.refFileType;
      this.refFilePath = memo.refFilePath;
      this.refFileName = memo.refFileName;
      this.createdTimeMilli = memo.createdTimeMilli;
    },
    async downloadAttach() {
      const response = await downloadAttach(this.id);
      if (!response) {
        return false;
      }
      const blob = new Blob([response.data], {
        type: response.headers["content-type"]
      });
      const fileUrl = URL.createObjectURL(blob);
      // 브라우저 다운로드 기능 호출
      const link = document.createElement("a");
      link.href = fileUrl;
      link.download = this.refFileName;
      link.click();
      // URL 객체 해제
      URL.revokeObjectURL(fileUrl);
    },
    convertDateFromMillis(millis) {
      return moment(millis).format("YYYY.MM.DD (dd) HH:mm");
    }
  }
};
</script>

<style lang="scss" scoped>
.memo-row {
  min-height: 24px;
  line-height: 24px;
}
</style>
