<template>
  <div class="py-2">
    <div class="font-weight-bold d-flex justify-space-between">
      <div>{{ item.userName }}</div>
      <div v-if="isTrashedItem" class="delete-time-text">
        {{ calculateDeleteTime(item) }}
      </div>
    </div>
    <div>{{ item.email }}</div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { convertLastChangeDate } from "@/commons/utils/moment";
import i18n from "@/_locales";

export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapGetters("customerRoute", ["getRouteInfo"]),
    isTrashedItem() {
      const { type } = this.getRouteInfo;
      return type === "TRASH";
    }
  },
  methods: {
    calculateDeleteTime(card) {
      const { deletedTimeMillis } = card;
      let deleteTime = new Date(deletedTimeMillis);
      deleteTime.setDate(deleteTime.getDate() + 7);
      const result = convertLastChangeDate(deleteTime) * -1;

      return result > 0
        ? i18n.t("customer.deleted.text1", { value: result }) // ?일뒤 자동 삭제됨
        : i18n.t("customer.deleted.text2"); // 곧 자동 삭제됨
    }
  }
};
</script>
<style lang="scss" scoped>
.delete-time-text {
  font-weight: unset;
  color: #f44336 !important;
  caret-color: #f44336 !important;
  font-size: 12px;
}
</style>
