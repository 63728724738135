<template>
  <div class="history-list-wrap" ref="historyArea" @scroll="onScroll">
    <v-timeline align-top dense key="history-time-line">
      <v-timeline-item
        small
        fill-dot
        color="teal lighten-2"
        v-for="(item, idx) in historyList"
        :icon="icon(item)"
        :key="idx"
      >
        <MemoPart
          v-if="item.historyType === 'MEMO'"
          :key="idx"
          :item="item"
          :idx="idx"
          :length="historyList.length"
        />
        <HistoryPart
          v-else
          :key="idx"
          :item="item"
          :idx="idx"
          :length="historyList.length"
        />
      </v-timeline-item>
    </v-timeline>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import MemoPart from "./MemoPart.vue";
import HistoryPart from "./HistoryPart.vue";

export default {
  components: { HistoryPart, MemoPart },
  props: {
    historyList: {
      type: Array
    },
    canGoScrollTop: {
      type: Boolean,
      default: false
    }
  },
  watch: {},
  computed: {
    ...mapGetters("auth", ["getUserInfo"]),
    ...mapGetters("customerRoute", ["getRouteInfo", "getPage"]),
    ...mapGetters("customerHistory", [
      "getPage",
      "getTotalPages",
      "getHistoryType"
    ]),
    ...mapGetters("customerGroup", ["getCurrentGroup"]),
    hasWritePermission() {
      const group = this.getCurrentGroup;
      // 그룹이 없거나 그룹이 삭제 상태면 편집 X
      if (!group || group.isDeleted || group.customerGroupType === "TRASH") {
        return false;
      }
      return group.hasPermission;
    }
  },
  created() {},
  mounted() {},
  methods: {
    ...mapActions("positioningMenu", ["closePositioningMenu"]),
    ...mapActions("customerHistory", ["loadHistoryList"]),
    icon(item) {
      switch (item.historyType) {
        case "HISTORY":
          return "mdi-history";
        case "MAIL":
          return "mdi-email-outline";
        default:
          return "mdi-note-outline";
      }
    },
    onScroll() {
      const div = this.$refs.historyArea;
      const scrollTop = div.scrollTop;
      const scrollHeight = div.scrollHeight;
      const clientHeight = div.clientHeight;

      if (scrollTop > 200) {
        this.$emit("update:canGoScrollTop", true);
      } else {
        this.$emit("update:canGoScrollTop", false);
      }

      if (Math.ceil(scrollTop + 1) + clientHeight >= scrollHeight) {
        this.loadMoreHistory();
      }
    },
    loadMoreHistory() {
      if (this.getPage >= this.getTotalPages) {
        return;
      }
      const { groupId } = this.getRouteInfo;
      const currentPage = this.getPage;
      this.loadHistoryList({
        groupId: groupId,
        page: currentPage + 1,
        historyType: this.getHistoryType
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.history-list-wrap {
  position: absolute;
  top: 50px;
  bottom: 0;
  width: 100%;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

.v-timeline {
  &:before {
    left: calc(33px - 10px) !important;
  }
  .v-timeline-item ::v-deep {
    justify-content: flex-end;
    .v-timeline-item__body {
      max-width: calc(100% - 60px);
    }
    .v-timeline-item__divider {
      min-width: 55px;
      justify-content: flex-start;
      .v-timeline-item__dot {
        margin-top: 10px;
        margin-left: 12px;
      }
    }
  }
}
</style>
