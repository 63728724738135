<template>
  <div>
    <v-card :loading="loading" flat>
      <v-card-text class="cr-search-card-area pa-0">
        <div v-if="searchedList.length <= 0" class="no-item pt-2 pb-8">
          {{ $t("common.79") }}
        </div>
        <div v-else>
          <v-simple-table class="cr-search-table">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center">
                    {{ $t("customer.name") }}
                  </th>
                  <th class="text-center">
                    {{ $t("customer.username") }}
                  </th>
                  <th class="text-center">
                    {{ $t("customer.tel") }}
                  </th>
                  <th class="text-center">
                    {{ $t("customer.email") }}
                  </th>
                  <th class="text-center"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in searchedList" :key="item.id">
                  <td class="text-center" v-text="item.groupName" />
                  <td
                    class="text-center"
                    v-html="
                      highlightKeyword(
                        item.userName,
                        searchType === searchTypes.ALL
                          ? keyword
                          : searchUserName
                      )
                    "
                  />
                  <td
                    class="text-center"
                    v-html="
                      highlightKeyword(
                        item.tel,
                        searchType === searchTypes.ALL ? keyword : searchTel
                      )
                    "
                  />
                  <td
                    class="text-center"
                    v-html="
                      highlightKeyword(
                        item.email,
                        searchType === searchTypes.ALL ? keyword : searchEmail
                      )
                    "
                  />
                  <td>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          v-on="on"
                          v-bind="attrs"
                          @click.stop="goGroup(item)"
                        >
                          <v-icon
                            >mdi-arrow-top-right-thin-circle-outline</v-icon
                          >
                        </v-btn>
                      </template>
                      {{ $t("customer.goGroup") }}
                    </v-tooltip>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </v-card-text>
      <Pagination
        v-if="totalPages > 0"
        :page.sync="page"
        :pageSize="pageSize"
        :blockSize="blockSize"
        :totalElements="totalElements"
        @pageClick="pageClick"
      />
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Pagination from "@/commons/views/Pagination.vue";
import moment from "moment";
import { GROUP_TYPES, searchTypes } from "@/customer/constant/constants";

export default {
  props: ["dialog"],
  components: { Pagination },
  data() {
    return {
      searchType: searchTypes.ALL,
      highlightWord: "",
      keyword: "",
      searchUserName: "",
      searchTel: "",
      searchEmail: "",
      page: 1,
      pageSize: 5,
      totalPages: 1,
      sort: "createdTimeMillis",
      blockSize: 10,
      totalElements: 0,
      searchedList: [],
      loading: true
    };
  },
  computed: {
    searchTypes() {
      return searchTypes;
    },
    ...mapGetters("auth", ["getUserInfo"]),
    ...mapGetters("customerDialog", ["getParams"])
  },
  async created() {
    const {
      searchType,
      keyword,
      searchUserName,
      searchTel,
      searchEmail
    } = await this.getParams;
    this.searchType = searchType;
    this.keyword = keyword;
    this.searchUserName = searchUserName;
    this.searchTel = searchTel;
    this.searchEmail = searchEmail;
    await this.fetchData();
  },
  methods: {
    convertDateFromMillis(millis) {
      return moment(millis).format("YYYY.MM.DD (dd) HH:mm");
    },
    ...mapActions("customerCard", ["searchCardList"]),
    pageClick(page) {
      this.loading = true;
      // 검색결과 선택과 스크롤 초기화
      this.page = page;
      this.fetchData();
    },
    async fetchData() {
      const params = {
        searchType: this.searchType,
        keyword: this.keyword,
        searchUserName: this.searchUserName,
        searchTel: this.searchTel,
        searchEmail: this.searchEmail,
        page: this.page - 1,
        pageSize: this.pageSize
      };

      const {
        cardList,
        totalElements,
        totalPages,
        page
      } = await this.searchCardList(params);

      this.searchedList = cardList;
      this.totalPages = totalPages;
      this.totalElements = totalElements;
      this.page = page;
      this.loading = false;
    },
    // 검색어 문자열 하이라이트
    highlightKeyword(fieldName, keyword) {
      if (!fieldName) {
        return;
      }
      const regex = new RegExp(`(${keyword})`, "gi");
      return fieldName.replace(regex, '<span class="highlighted">$1</span>');
    },
    goGroup(card) {
      this.$router.push({
        name: "customer_list",
        params: {
          type: GROUP_TYPES.CUSTOMER,
          groupId: card.groupId,
          page: 1
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.row-pointer :hover {
  cursor: pointer;
}
.v-data-table > .v-data-table__wrapper > table {
  thead > tr {
    height: 60px;
    &:first-child {
      width: 15%;
    }
    &:nth-child(1) {
      width: 15%;
    }
    &:nth-child(2) {
      width: 30%;
    }
    &:nth-child(3) {
      width: 30%;
    }
    &:last-child {
      width: 10%;
    }
  }
  tbody > tr ::v-deep {
    height: 60px;
    .highlighted {
      background-color: yellow;
      color: black;
    }
  }
}

.no-item {
  text-align: center;
  font-size: 16px;
}

.cr-search-card-area {
  border: thin solid rgba(0, 0, 0, 0.12);
  min-height: 360px;
}
</style>
