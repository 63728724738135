<template>
  <Toolbar
    class="cr-customer-list-toolbar"
    :items="items"
    :rightItems="rightItems"
    :checkbox.sync="checkbox"
    :indeterminate="indeterminate"
    @update:checkbox="c => $emit('toggleCheckbox', c)"
  />
</template>

<script>
import i18n from "@/_locales";
import { /*mapMutations, mapActions,*/ mapGetters } from "vuex";
import Toolbar from "@/commons/views/toolbar/Toolbar";

export default {
  props: ["selectedItems", "showConsonant"],
  components: { Toolbar },

  computed: {
    ...mapGetters("customerCard", [
      "getDefaultHeaders",
      "getHeaders",
      "getCardList"
    ]),
    ...mapGetters("auth", ["getUserInfo"]),
    ...mapGetters("contactConfig", ["isUseSelectBox"]),
    ...mapGetters("customerRoute", ["getRouteInfo"]),
    ...mapGetters("selectBox", ["getSelectBoxItems"]),
    ...mapGetters("customerGroup", ["getCustomerAdminList", "getCurrentGroup"]),
    items: function() {
      const selectedCards = this.isUseSelectBox
        ? this.getSelectBoxItems
        : this.selectedItems;
      const singleDisabled = selectedCards.length < 1;
      const { customerGroupType, isDeleted, hasPermission } =
        this.getCurrentGroup || {}; // 옵셔널 체이닝
      /*
      const { id: userId } = this.getUserInfo;
      const isAdmin = this.getCustomerAdminList.some(
        admin => admin.userDetail.userId === userId
      );
      */
      const menuItems = {
        default: [
          {
            title: i18n.t("common.삭제"),
            disabled: singleDisabled || !hasPermission,
            click: () => this.$emit("deleteCardList", "move")
          },
          {
            title: i18n.t("contact.메일쓰기"),
            disabled: singleDisabled,
            click: () => this.$emit("openMailWritePopup", selectedCards)
          },
          {
            title: i18n.t("contact.인쇄"),
            disabled: singleDisabled,
            class: "cr-tutorial-contact-print",
            click: () => this.$emit("print")
          }
        ],
        trash: [
          {
            title: i18n.t("customer.restore.title"),
            disabled: singleDisabled,
            click: () => this.$emit("openRestoreCardDialog")
          }
        ]
      };

      if (isDeleted) return [];
      return menuItems[customerGroupType === "TRASH" ? "trash" : "default"];
    },
    rightItems: function() {
      const headerList = [];
      this.getDefaultHeaders.forEach((header, index) => {
        if (!header.default) {
          headerList.push({
            title: header.text,
            check: () => this.updateConfigHideColumns({ index, header }),
            checked: header.use
          });
        }
      });

      return [
        {
          title: "필터", // i18n.t("contact.초성"),
          click: () => this.$emit("update:showConsonant", !this.showConsonant)
        }
      ];
    }
  },
  methods: {},
  watch: {
    selectedItems(items) {
      const a = this.getCardList.filter(c => !!items.find(i => i.id === c.id));
      if (a.length !== 0 && a.length === this.getCardList.length) {
        this.checkbox = true;
        this.indeterminate = false;
      } else if (a.length === 0) {
        this.checkbox = false;
        this.indeterminate = false;
      } else {
        this.indeterminate = true;
      }
    }
  },
  data: () => ({
    checkbox: false,
    indeterminate: false
  })
};
</script>
<style lang="scss" scoped>
.cr-customer-list-toolbar ::v-deep {
  .v-input--checkbox {
    top: unset;
    left: unset;
    padding-left: 16px;
    padding-top: 3px;
  }
}
</style>
