<template>
  <div class="cr-card-detail-header">
    <span class="text-subtitle-2">{{ title }}</span>
    <v-spacer />
    <div v-if="!isEdit">
      <v-btn
        v-if="showBtn"
        small
        elevation="0"
        color="primary"
        @click="e => changeEditStatus()"
      >
        {{ btnText }}
      </v-btn>
    </div>
    <div v-else>
      <v-btn small elevation="0" color="primary" @click="$emit('updateGroup')">
        {{ btnText }}
      </v-btn>
      <v-btn
        v-if="isEdit"
        small
        elevation="0"
        outlined
        color="grey"
        class="ml-2"
        @click="changeEditStatus()"
      >
        취소
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    title: {
      type: String,
      required: true
    },
    showBtn: {
      type: Boolean,
      required: false,
      default: true
    },
    isEdit: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  computed: {
    ...mapGetters("customerGroup", ["getHasWritePermission"]),
    btnText() {
      return this.isEdit ? "저장" : "수정";
    }
  },
  methods: {
    changeEditStatus() {
      return this.$emit("update:isEdit", !this.isEdit);
    }
  }
};
</script>

<style lang="scss" scoped>
.cr-card-detail-header::v-deep {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
</style>
