var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cr-card-detail-header"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":hover ? 'primary' : ''},on:{"click":_vm.openMailWritePopup}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-email-edit")])],1)]}}],null,true)})]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("contact.메일쓰기")))])]),(false)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":hover ? 'primary' : ''}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-calendar-edit")])],1)]}}],null,true)})]}}],null,false,445739516)},[_c('span',[_vm._v("일정 잡기")])]):_vm._e(),_c('v-spacer'),(_vm.hasWritePermission)?_c('ModifyBtn',_vm._g({},_vm.$listeners)):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }