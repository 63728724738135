<template>
  <div
    style="position: relative"
    @dragenter="e => dragenter(e)"
    @dragleave="e => dragleave(e)"
    @drop="e => addFileByDrop(e)"
    @dragover.prevent
  >
    <v-form
      ref="form"
      lazy-validation
      @submit.prevent="submitForm"
      v-if="hasWritePermission"
      class="mb-9"
    >
      <!-- 메모 -->
      <div :style="`height: ${editorHeight}px`" class="editor-wrap">
        <TinymceEditor
          v-if="editorLoaded"
          key="historyEditor"
          ref="historyEditor"
          editorId="historyEditor"
          :editorContent.sync="memoContent"
          :editorHeight="editorHeight"
          :useImage="true"
          :useImagePaste="false"
          :useFontSelect="false"
          :useFontSizeSelect="false"
          :useLineHeight="false"
          :useDent="false"
          :use-full-screen="false"
          :use-code="false"
          :use-undo-redo="false"
          :use-image-upload="false"
          :use-image="false"
        />
      </div>
      <div class="d-flex justify-space-between">
        <v-file-input
          class="file-input"
          show-size
          ref="attachRef"
          truncate-length="20"
          v-model="attach"
          :rules="attach != null ? attachRules : []"
        ></v-file-input>
        <v-spacer />
        <v-btn
          small
          elevation="0"
          color="primary"
          class="mt-4"
          @click="submitForm"
        >
          저장
        </v-btn>
      </div>

      <v-row>
        <v-col cols="12" class="py-0"> </v-col>
      </v-row>
    </v-form>

    <v-overlay
      :style="overlay ? 'border: 4px solid #1867c0;' : ''"
      absolute
      opacity="0.85"
      v-model="overlay"
    >
      <div class="d-flex justify-center">
        <v-icon class="mb-5 mx-auto" style="font-size: 110px;">
          mdi-cloud-upload
        </v-icon>
      </div>
      <div v-text="$t('board.68')"></div>
    </v-overlay>
  </div>
</template>

<script>
import TinymceEditor from "@/customer/views/components/common/tinymce/TinymceEditor.vue";
import { mapActions, mapGetters } from "vuex";
import i18n from "@/_locales";
import { getUserDetail } from "@/commons/api/auth.api";

const maxAttachSize = 20; // MB

export default {
  components: { TinymceEditor },
  data() {
    return {
      mode: "write",
      title: `${i18n.t("customer.ROOT")} ${i18n.t("customer.memo.title")}`,
      persistent: true,
      id: 0,
      memoContent: "",
      userId: 0,
      userName: "",
      accountName: "",
      groupId: 0,
      contentType: "CARD",
      refFileType: null,
      refFilePath: null,
      refFileName: null,
      attach: null,
      editorHeight: 250,
      overlay: false,
      counter: 0,
      editorLoaded: false,
      attachRules: [
        v =>
          (v && v.size < maxAttachSize * 1048576) ||
          i18n.t("customer.validate.memoAttach", { value: maxAttachSize })
      ]
    };
  },
  computed: {
    ...mapGetters("customerGroup", ["getCurrentGroup"]),
    hasWritePermission() {
      const group = this.getCurrentGroup;
      // 그룹이 없거나 그룹이 삭제 상태면 편집 X
      if (!group || group.isDeleted || group.customerGroupType === "TRASH") {
        return false;
      }
      return group.hasPermission;
    }
  },
  created() {
    this.initData();
    this.initEditor();
  },
  watch: {
    getCurrentGroup() {
      this.initData();
      this.initEditor();
    }
  },
  methods: {
    ...mapActions("snackbar", ["openSnackbar"]),
    ...mapActions("confirm", { confirmDialog: "confirm" }),
    ...mapActions("customerHistory", ["addCustomerMemo", "updateCustomerMemo"]),
    async addMemoFunc() {
      const { userId, groupId, attach } = this;
      const formData = new FormData();
      formData.append("userId", userId);
      formData.append("content", this.memoContent);
      formData.append("groupId", groupId);
      formData.append("attach", attach);

      const result = await this.addCustomerMemo(formData);

      // 다이얼로그 닫기
      if (result) {
        this.resetData();
        this.initEditor();
      }
    },
    async initData() {
      const { data: userData = {} } = await getUserDetail();
      const { accountName, username, id } = userData;
      const { id: groupId } = this.getCurrentGroup;
      this.groupId = groupId;
      this.userId = id;
      this.username = username;
      this.accountName = accountName;
    },
    async submitForm() {
      if (!this.isValid()) {
        return false;
      }

      await this.addMemoFunc();
    },
    resetData() {
      this.id = 0;
      this.contentType = "GROUP";
      this.attach = null;
      this.refFileName = null;
      this.refFileType = null;
      this.memoContent = "";
    },
    dragenter(e) {
      e.preventDefault();
      e.stopPropagation();
      this.counter += 1;
      this.overlay = true;
    },
    dragleave(e) {
      e.preventDefault();
      e.stopPropagation();
      this.counter -= 1;
      if (this.counter === 0) {
        this.overlay = false;
      }
    },
    addFileByDrop(e) {
      e.preventDefault();
      this.counter = 0;
      this.overlay = false;
      this.attach = e.dataTransfer.files[0];
    },
    isValid() {
      let validateCount = 0;
      // 메모 유효성 체크
      if (!this.memoContent) {
        this.errorMessage = i18n.t("customer.validate.memo");
        validateCount++;
      }

      // 첨부파일 용량 체크
      if (this.attach && this.attach.size > maxAttachSize * 1048576) {
        this.errorMessage = i18n.t("customer.validate.memoAttach", {
          value: maxAttachSize
        });
        validateCount++;
      }

      // 유효성 체크 결과에 따른 confirm 초기화 처리
      if (validateCount > 0) {
        this.openSnackbar({ message: this.errorMessage, type: "ERROR" });
        return false;
      }

      this.errorMessage = "";
      return true;
    },
    initEditor() {
      setTimeout(() => {
        this.editorLoaded = true;
        this.memoContent = "";
      }, 50);
    }
  }
};
</script>

<style lang="scss" scoped>
.editor-wrap {
  ::v-deep .tox-tinymce {
    border: 1px solid rgba(0, 0, 0, 0.12) !important;
    border-radius: 12px !important;
  }
}
.file-input ::v-deep {
  .v-input__slot::before {
    border-color: rgba(0, 0, 0, 0) !important;
  }
  .v-input__control {
    flex-grow: 0;
    width: initial;
  }
}
</style>
